import React from 'react'
import Layout from '../components/Layout/Layout'
import Container from '../components/common/Container/Container'

const AboutPage = () => (
  <Layout>
    <Container small>
      <h2>ABOUT KAI KAMERA</h2>
      <p>
        My name is Kai. I’m a Ukrainian boy, I was born in Prague, Czech
        Republic so I don’t understand why my parents call me Ukrainian. I go to
        international school of Prague. I speak 3 languages, English, Ukrainian
        and Russian, right now English is my favorite.
      </p>
      <p>
        I like to take pictures of everything and anything. I take pictures to
        remember the moments of life or something I’ve built or created. So that
        I can look at them when I grow up.
        {' '}
      </p>
      <p>
        I like to build things, I love LEGO, hot wheels, all kinds of big and
        small cars with 4 or 5 or 6 or even 10 wheels and cars on tracks. I love
        playing in water and with water.
      </p>
      <p>
        I love amusement parks and aqua parks, climbing and running, riding my
        bike. Books are awesome, I listen to them in different languages, and
        I’m learning how to read.
        {' '}
      </p>
      <p>
        Sometimes I’m scared of monsters even though I know that they are not
        real, just like Santa, sorry guys, I know the truth...
      </p>
    </Container>
  </Layout>
)

export default AboutPage
